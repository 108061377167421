import React, { useEffect } from 'react'
import '.././App.css';
import { auth } from '../firebase';

function Header() {

    const signOut = () => {
        auth.signOut();
        window.location.href = "/login";
    }
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
        });
    });



    return (
        <div>
            {/* <div className="preloader flex-column justify-content-center align-items-center">
        <img className="animation__shake" src="../dist/img/AdminLTELogo.png" alt="AdminLTELogo" height={60} width={60} />
        </div> */}

            <nav className="main-header navbar navbar-expand navbar-orange navbar-light">
                {/* Left navbar links */}
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="#0" role="button"><i style={{ color: "white" }} className="fas fa-bars" /></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#0" >
                            <b style={{ color: "white", textTransform: "uppercase" }}> SKYLAND HOTEL</b>
                        </a>
                    </li>

                </ul>
                {/* Right navbar links */}
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <a className="nav-link" onClick={() => signOut()} href="#0">
                            <i style={{ color: "white" }} className="fa fa-sign-out" />
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    )
}
export default Header