import React, { useState, useEffect } from 'react'
import { auth, db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

function Menu() {
    const [userData, setUserData] = useState();
    const [loadincount, setloadincount] = useState(true);
    const [username, setusername] = useState("");
    const [role, setrole] = useState("");


    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
            fetchData1()
        });
    });

    const fetchData1 = async () => {
        try {
            if (userData) {
                if (loadincount) {
                    const docRef = doc(db, "users", userData.uid)
                    const docSnap = await getDoc(docRef)
                    if (docSnap.exists()) {
                        setrole(docSnap.data().role);
                        setusername(docSnap.data().username);
                        setloadincount(false)
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <div>
            <aside className="main-sidebar sidebar-dark-primary elevation-4">
                {/* Brand Logo */}
                <a href="index3.html" className="brand-link">
                    <img src={window.location.origin + '/user.jpg'} alt="A" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
                    <span className="brand-text font-weight-light">{username} </span>
                </a>
                <div className="sidebar">
                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

                            <li className="nav-item">
                                <a href="/room-cleaning" className="nav-link">
                                    <i className="nav-icon fa fa-remove" />
                                    <p>Cleaning Rooms</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/room-cleaned" className="nav-link">
                                    <i className="nav-icon fa fa-check" />
                                    <p>Cleaned Rooms</p>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>
        </div>
    )
}
export default Menu