import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <div>
                <footer className="main-footer">
                    <div className="float-right d-none d-sm-block">
                        <b>Version</b> 3.1.0
                    </div>
                    <strong>Copyright &copy; 2024-2025 <a href="#0" target={"_blank"}></a>. </strong> All rights reserved.
                </footer>
              
            </div>

        )
    }
}
