import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { auth, db } from './firebase';

import Login from './auth/Login';
import Register from './auth/Register';
import Header from './panel/Header';
import Menu from './panel/Menu';
import Footer from './panel/Footer';

import Forgetpass from './auth/Forgetpass';
import Roomcleanings from './components/booking/Roomcleaning';
import Roomcleaned from './components/booking/Roomcleaned';


function App() {
  const [user, setUser] = useState();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUser(user);
    });
  });




  return (
    <div className="wrapper">
      {
        !user ?
          <Router>
            <Routes>
              <Route path='/' element={<Login />} />
              <Route path='/login' element={<Login />} />
              <Route path='/Register' element={<Register />} />
              <Route path='/forgot-password' element={<Forgetpass />} />
            </Routes>
          </Router>
          :
          <>
            <Menu />
            <Header />
            <Router>
              <Routes>
                <Route path='/' element={<Roomcleanings />} />
                <Route path='/home' element={<Roomcleanings />} />
                <Route path='/room-cleaning' element={<Roomcleanings />} />
                <Route path='/room-cleaned' element={<Roomcleaned />} />
              </Routes>
            </Router>
            <Footer />
          </>
      }

    </div >
  );
}

export default App;
